import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UrlConstants } from 'src/app/constants/url.constants';
import { BloodGroup, Caste, Diet, Education, EmployedIn, FamilyStatus, FamilyValueOrPersonalValue, Fashion, Gender, MaritalStatus, MemberEducation, MemberFamilyInformation, MemberLanguage, MemberProfession, OnBehalf, PhysicalStatus, Profession, Religion } from '../models/api.models';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(private http: HttpClient) { }

//Method to get gender data via API
  public getGender(){
    return this.http.get(UrlConstants.gender);
  }
  
  //Method to get Onbehalf data via API
  public getOnBehalf() {
    return this.http.get(UrlConstants.onbehalf);
  }
  
  //Method to get maritalStatus data via Api
  public getMaritalStatus() {
    return this.http.get(UrlConstants.maritalStatus);
  }

  //Method to get caste data via API
  public  getCaste() {
    return this.http.get(UrlConstants.caste);
  }

  //Method to get caste data via API
  public  getCasteByReligion(religionId:String) {
      return this.http.get(UrlConstants.casteByReligion+religionId);
  }
  

  //Method to get education data via API
  public  getEducation() {
    return this.http.get(UrlConstants.education);
  }

  //Method to get religion data via API
  public getReligion() {
    return this.http.get(UrlConstants.religion);
  }

  //Method to get profession data via APi
  public getProfession() {
    return this.http.get(UrlConstants.profession);
  }

  //Method to get employedIn data via APi
  public  getEmployedIn() {
    return this.http.get(UrlConstants.employedIn);
  }

  //Method to get bloodgroup data via API
  public getBloodgroup() {
    return this.http.get(UrlConstants.bloodgroup);
  }

  //Method to get diet data via API
  public getDiet() {
    return this.http.get(UrlConstants.diet);
  }

  //Method to get familystatus data via API
  public getFamilyStatus() {
    return this.http.get(UrlConstants.familystatus);
  }

  //Method to get familyvalue data via API
  public getFamilyValue() {
    return this.http.get(UrlConstants.familyValue);
  }

  //Method to get fashion data via API
  public getFashion() {
    return this.http.get(UrlConstants.fashion);
  }

  //Method to get physicalStatus data via API
  public getPhysicalStatus() {
    return this.http.get(UrlConstants.physicalStatus);
  }


  



}
