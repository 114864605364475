import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewEncapsulation } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APIRes, Caste, Gender, MaritalStatus, OnBehalf, Religion } from '../../models/api.models';
import { MasterDataService } from '../../services/master-data.service';
import { Router } from '@angular/router';
import { MemberService } from '../../services/member.service';
import { first } from 'rxjs/internal/operators';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-index-it-solution-two',
  templateUrl: './index-it-solution-two.component.html',
  styleUrls: ['./index-it-solution-two.component.css']
})

/***
 * It-Solution-Two Component
 */
export class IndexItSolutionTwoComponent implements OnInit {

  signupForm: FormGroup;
  submitted = false;
  error = '';
  successmsg = false;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  // set the currenr year
  year: number = new Date().getFullYear();

  selectedGender: Number;
  selectedOnBehalf: Number;
  selectedMaritialStatus: Number;
  selectedReligion: Number;
  selectedCaste: Number;
  genders: Gender[];
  maritialStatuses: MaritalStatus[];
  onBehalfs: OnBehalf[];
  religions: Religion[];
  castes: Caste[];
  /***
   * nav bg class class set
   */
  navClass = 'nav-light';
  buttonList = true;

  /**
   * Client Testimonial Data
   */
  testimonialData = [
    {
      profile: "assets/images/client/01.jpg",
      name: "Happy Couple",
      // designation: "C.E.O",
      message: `" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "`
    },
    {
      profile: "assets/images/client/02.jpg",
      name: "Happy Couple",
      //designation: "M.D",
      message: `" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "`
    },
    {
      profile: "assets/images/client/03.jpg",
      name: "Happy Couple",
      //designation: "P.A",
      message: `" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "`
    },
    {
      profile: "assets/images/client/04.jpg",
      name: "Happy Couple",
      //  designation: "Manager",
      message: `" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "`
    },
    {
      profile: "assets/images/client/05.jpg",
      name: "Happy Couple",
      // designation: "Developer",
      message: `" There is now an abundance of readable dummy texts. These are usually used when a text is required. "`
    },
    {
      profile: "assets/images/client/06.jpg",
      name: "Happy Couple",
      // designation: "Designer",
      message: `" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "`
    }
  ];

  /**
   * Blog Data
   */
  blogData = [
    {
      image: "assets/images/blog/01.jpg",
      title: "Design your apps in your own way",
      like: "33",
      message: "08",
      name: "Calvin Carlo",
      date: "13th August, 2019"
    },
    {
      image: "assets/images/blog/02.jpg",
      title: "How apps is changing the IT world",
      like: "33",
      message: "08",
      name: "Calvin Carlo",
      date: "13th August, 2019"
    },
    {
      image: "assets/images/blog/03.jpg",
      title: "Smartest Applications for Business",
      like: "33",
      message: "08",
      name: "Calvin Carlo",
      date: "13th August, 2019"
    }
  ];

  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, private router: Router, private memberService: MemberService, private masterDataService: MasterDataService,) { }

  ngOnInit(): void {
    this.fetchData();
    this.signupForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      password: ['', Validators.required],
      genderId: [this.selectedGender, Validators.required],
      dateOfBirth: [null, Validators.required],
      religionId: ['', Validators.required],
      casteId: ['', Validators.required],
      onBehalfId: ['', Validators.required],
      maritalStatusId: ['', Validators.required]
    });
  }

  fetchData() {
    //calling gender
    this.masterDataService.getGender().subscribe((data: APIRes<Gender[]>) => {
      this.genders = data.data;
      console.log(this.genders);

    });
    this.masterDataService.getReligion().subscribe((data: APIRes<Religion[]>) => {
      this.religions = data.data;
    });

    this.masterDataService.getCaste().subscribe((data: APIRes<Caste[]>) => {
      this.castes = data.data;
    });

    this.masterDataService.getMaritalStatus().subscribe((data: APIRes<MaritalStatus[]>) => {
      this.maritialStatuses = data.data;
    });

    this.masterDataService.getOnBehalf().subscribe((data: APIRes<OnBehalf[]>) => {
      this.onBehalfs = data.data;
    });

  }


  // convenience getter for easy access to form fields
  get f() { return this.signupForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.signupForm.invalid) {
      return;
    } else {
      const signupForm: FormGroup = this.signupForm;
      console.log(this.signupForm.value);
      let mobileNumberTemp = this.signupForm.get('phoneNumber').value;
      let firstName = this.signupForm.get('firstName').value;
      let lastName = this.signupForm.get('lastName').value;
      let email = this.signupForm.get('email').value;
      let password = this.signupForm.get('password').value;
      let genderId = this.signupForm.get('genderId').value;
      let dateOfBirth = this.signupForm.get('dateOfBirth').value;
      let religionId = this.signupForm.get('religionId').value;
      let casteId = this.signupForm.get('casteId').value;
      let onBehalfId = this.signupForm.get('onBehalfId').value;
      let maritalStatusId = this.signupForm.get('maritalStatusId').value;
      let mobileNumber = mobileNumberTemp.e164Number;

      this.signupForm.setValue({
        phoneNumber: mobileNumber, firstName: firstName, lastName: lastName, email: email, password: password,
        genderId: genderId, dateOfBirth: dateOfBirth, religionId: religionId, casteId: casteId, onBehalfId: onBehalfId, maritalStatusId: maritalStatusId
      });

      //   this.signupForm.removeControl("religionId");
      this.memberService.signUp(this.signupForm.value)
        .pipe(first())
        .subscribe(
          data => {
            this.successmsg = true;
            if (this.successmsg) {
              // this.router.navigate(['https://app.findone.dataequinox.com']);
              setTimeout(() => { window.location.href = 'https://app.findone.dataequinox.com'; }, 2000)

            }
          },
          error => {
            this.error = error ? error : '';
            console.log(JSON.stringify( error.error.message));
            
          });
    }
  }


  selectGender() {
    console.log(this.selectedGender);
    this.signupForm.patchValue({ genderId: this.selectedGender });
    console.log(this.signupForm.value);
  }

  selectOnBehalf() {
    console.log(this.selectedOnBehalf);
    this.signupForm.patchValue({ onBehalfId: this.selectedOnBehalf });
    console.log(this.signupForm.value);
  }
  selectMaritialStatus() {
    console.log(this.selectedMaritialStatus);
    this.signupForm.patchValue({ maritialStatusId: this.selectedMaritialStatus });
    console.log(this.signupForm.value);
  }

  selectReligion() {
    console.log(this.selectedReligion);
    this.signupForm.patchValue({ religionId: this.selectedReligion });
    console.log(this.signupForm.value);
  }
  selectCaste() {
    console.log(this.selectedCaste);
    this.signupForm.patchValue({ casteId: this.selectedCaste });
    console.log(this.signupForm.value);
  }


  /**
  * Open modal for show the video
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }


}
