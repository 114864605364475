<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> About us </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">DATAEQUINOX</a></li>
                <li class="breadcrumb-item active" aria-current="page">About Us</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- About Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="position-relative">
          <img src="assets/images/company/about.jpg" class="rounded img-fluid mx-auto d-block" alt="">
          <div class="play-icon">
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="play-btn">
              <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
            </a>
            <ng-template #content let-modal>
              <!-- <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div> -->
              <!-- <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div> -->
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">Our Story</h4>
          <p class="text-muted"><span class="text-primary fw-bold">dataEquinox</span> was started as the product
            development provider of Aventuri targetting an access to the biggest IT talent pool in the world. When
            dataEquinox started solving problems and providing solutions, we found out that many people across the globe
            are in search of similar technical talent at affordable rates. So we decided provide consulting options
            beyond the
            scope of Aventuri with the aim of scaling it globally. Now we have 4 customers and 3 Applications due for
            Production as on June 2021. We believe in a result oriented delivery model that is based on quality over
            quantity with a design first approach.
            This has helped us to pave path for the baby steps of our growth.
          </p>
          
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

</section>
<!--end section-->
<!-- About End -->

<!-- Team Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Greatest Minds</h4>
          <p class="text-muted para-desc mx-auto mb-0">Meet the core team behind <span
              class="text-primary fw-bold">dataEquinox</span> that can provide everything you need</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let data of memberData">
        <div class="card team text-center bg-transparent border-0">
          <div class="card-body p-0">
            <div class="position-relative">
              <img src="{{data.profile}}" class="img-fluid avatar avatar-ex-large rounded-circle" alt="">
              <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded ms-1"
                    *ngFor="let item of data.list">
                    <i-feather name="{{item}}" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul>
              <!--end icon-->
            </div>
            <div class="content pt-3 pb-3">
              <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">{{data.name}}</a></h5>
              <small class="designation text-muted">{{data.designation}}</small>
            </div>
          </div>
        </div>
      </div>
      <!--end row-->
    </div>
    <!--end container-->

    <!--end section-->
    <!-- Team End -->

    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      [ngxScrollTo]="'#home'">
      <i-feather name="arrow-up" class="icons"></i-feather>
    </a>
    <!-- Back to top -->