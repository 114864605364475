import { environment } from "src/environments/environment";

export const UrlConstants = {
    signUp : environment.apiHost   + '/api/member',
    story : environment.apiHost + '/api/members/posts/posttype/1',
    gender : environment.apiHost + '/api/member/gender',
    religion : environment.apiHost + '/api/member/religion',
    caste : environment.apiHost + '/api/member/caste',
    casteByReligion : environment.apiHost + '/api/member/caste?religionId=',
    education : environment.apiHost + '/api/member/education',
    onbehalf : environment.apiHost + '/api/member/onbehalf',
    profession : environment.apiHost + '/api/member/profession',
    employedIn : environment.apiHost + '/api/member/employedin',
    bloodgroup : environment.apiHost + '/api/member/bloodgroup',
    diet : environment.apiHost + '/api/member/diet',
    familystatus : environment.apiHost + '/api/member/familystatus',
    familyValue : environment.apiHost + '/api/member/familyvalue',
    fashion : environment.apiHost + '/api/member/fashion',
    physicalStatus : environment.apiHost + '/api/member/physicalstatus',
    maritalStatus : environment.apiHost + '/api/member/maritalstatus',

}