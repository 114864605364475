<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Services </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">DATAEQUINOX</a></li>
                <li class="breadcrumb-item active" aria-current="page">Services</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Feature Start -->
<section class="section">
  <div class="container">
    <app-services [servicesData]="servicesData"></app-services>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">We Provide IT & Business Solutions</h4>
          <p class="text-muted para-desc mx-auto mb-0">We can help you to answer all your problems through technology.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="monitor" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Innovative Thinking</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Technology</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="eye" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Software Development</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="smartphone" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Mobile & Web</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="aperture" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Branding and Design</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="bold" class="fea icon-ex-md text-primary"></i-feather>>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Business Idea</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="feather" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Content Strategy</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">User Experience</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="bar-chart" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Data Analytics</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="git-merge" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Artificial Intelligence</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="settings" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Infrastrucutre & Monitoring</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="Search" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Cybersecurity</h4>
          </div>
        </div>
      </div>
      <!--end col-->

    </div>
    <!--end row-->
  </div>
  <!--end container-->

</section>
<!--end section-->
<!-- Feature Start -->

<!-- Partners start -->
<section class="py-4 border-bottom border-top">
  <div class="container">
    <app-clients-logo></app-clients-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->