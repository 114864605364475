
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Member } from '../models/api.models';
import { RegisterMember } from '../models/register-member.models';
import { UrlConstants } from 'src/app/constants/url.constants';


@Injectable({
    providedIn: 'root'
})
export class MemberService {

    private signedInMemberSubject: BehaviorSubject<Member>;
    public signedInMember: Observable<Member>;
    public static signedInMemberAPIState: string;


    constructor(private http: HttpClient) {
        this.signedInMemberSubject = new BehaviorSubject<Member>(JSON.parse(localStorage.getItem('signedInMember')));
        this.signedInMember = this.signedInMemberSubject.asObservable();
    }

    signUp(registerMember: RegisterMember) {
        registerMember.otp = 123456; //TODO remove
        return this.http.post(UrlConstants.signUp, registerMember);
      }
    
}