<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 it-home" style="background: url('assets/images/bg.jpg') center center;"
    id="home">
    <div class="bg-overlay red-overlay"></div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 col-12">
                <div class="title-heading">
                    <h6 class="text-white-50">FindOne Matrimony</h6>
                    <h1 class="fw-bold text-white title-dark mt-2 mb-3">Think Marriage <br> Think Find One.</h1>
                    <p class="para-desc text-white-50">FindOne Matrimony.com will help you find your perfect partner for life</p>
                    <div class="mt-4 pt-2">
                        <a href="https://app.findone.dataequinox.com" class="btn btn-primary m-1"><i class="uil uil-user "></i> Login</a>
                        <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
                            data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
                            <i-feather name="video" class="icons"></i-feather>
                        </a><small class="fw-bold text-uppercase text-light title-dark align-middle ms-1"></small>

                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780"
                                    frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="card shadow rounded border-0 ms-lg-5">
                    <div class="card-body">
                        <h5 class="card-title fw-bold">Register and Join our Community</h5>
                        <h6> To find your betterhalf with us </h6>

                        <form class="login-form mt-4" name="signupForm" [formGroup]="signupForm"
                            (ngSubmit)="onSubmit()">
                            <ngb-alert type="success" *ngIf="successmsg" [dismissible]="false">Registeration
                                successfull.
                            </ngb-alert>

                            <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error.error.message }}</ngb-alert>

                            <div class="row">
                                <aw-wizard [navBarLayout]="'large-empty-symbols'">
                                    <aw-wizard-step>
                                        <ng-template awWizardStepSymbol>
                                            <i class="fas fa-user"></i>
                                        </ng-template>
                                        <div class="row">
                                            <div class="col-12">

                                                <div class="mb-3 row">
                                                    <label class="col-md-4 col-form-label" for="firstName">First
                                                        Name</label>
                                                    <div class="col-md-8">
                                                        <input type="text" formControlName="firstName"
                                                            class="form-control"
                                                            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                                                            id="firstName" placeholder="Enter First Name" />

                                                        <div *ngIf="submitted && f.firstName.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.firstName.errors.required">First Name is
                                                                required</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-3 row">
                                                    <label class="col-md-4 col-form-label" for="lastName">Last
                                                        Name</label>
                                                    <div class="col-md-8">
                                                        <input type="text" formControlName="lastName"
                                                            class="form-control"
                                                            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
                                                            id="lastName" placeholder="Enter Last Name" />

                                                        <div *ngIf="submitted && f.lastName.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.lastName.errors.required">Last Name is
                                                                required</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-3 row">
                                                    <label class="col-md-4 col-form-label">Gender :</label>
                                                    <div class="col-md-8">
                                                        <ng-select placeholder="Select Gender" [items]="genders"
                                                            bindLabel="name" bindValue="genderId"
                                                            (change)="selectGender()" [(ngModel)]="selectedGender"
                                                            formControlName="genderId" [clearable]="false"
                                                            [ngClass]="{ 'is-invalid': submitted && f.genderId.errors}">
                                                        </ng-select>
                                                        <div *ngIf="submitted && f.genderId.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.genderId.errors.required">Gender is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-3 row">
                                                    <label class="col-md-4 col-form-label">Religion :</label>
                                                    <div class="col-md-8">
                                                        <ng-select [items]="religions" placeholder="Select Religion"
                                                            bindLabel="name" bindValue="religionId"
                                                            (change)="selectReligion()" [(ngModel)]="selectedReligion"
                                                            formControlName="religionId" [clearable]="false"
                                                            [ngClass]="{ 'is-invalid': submitted && f.religionId.errors}">
                                                        </ng-select>
                                                        <div *ngIf="submitted && f.religionId.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.religionId.errors.required">Religion is
                                                                required</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <ul class="list-inline wizard mb-0">
                                            <li class="next list-inline-item float-end"><button class="btn btn-primary"
                                                    awNextStep>Register</button>
                                            </li>
                                        </ul>
                                    </aw-wizard-step>

                                    <aw-wizard-step>
                                        <ng-template awWizardStepSymbol>
                                            <i class="fas fa-user-circle"></i>
                                        </ng-template>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="mb-3 row">
                                                    <label class="col-md-4 col-form-label">Date of Birth :</label>
                                                    <div class="col-md-8">
                                                        <input class="form-control" type="date"
                                                            formControlName="dateOfBirth"
                                                            [ngClass]="{ 'is-invalid': submitted && f.dateOfBirth.errors }">
                                                        <div *ngIf="submitted && f.dateOfBirth.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.dateOfBirth.errors.required">Date of Birth is
                                                                required</div>
                                                        </div>
                                                    </div>

                                                </div>
                                   
                                                <div class="mb-3 row">
                                                    <label class="col-md-4 col-form-label">Caste :</label>
                                                    <div class="col-md-8">
                                                        <ng-select [items]="castes" bindLabel="name" placeholder="Select Caste"
                                                            bindValue="casteId" (change)="selectCaste()" [(ngModel)]="selectedCaste"
                                                            formControlName="casteId" [clearable]="false"
                                                            [ngClass]="{ 'is-invalid': submitted && f.casteId.errors}">
                                                        </ng-select>
                                                        <div *ngIf="submitted && f.casteId.errors" class="invalid-feedback">
                                                            <div *ngIf="f.casteId.errors.required">Caste is required</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-3 row">
                                                    <label class="col-md-4 col-form-label">Maritial Status :</label>
                                                    <div class="col-md-8">
                                                        <ng-select [items]="maritialStatuses" placeholder="Select Marital Status"
                                                            bindLabel="name" bindValue="maritalStatusId" (change)="selectMaritialStatus()"
                                                            [(ngModel)]="selectedMaritialStatus" formControlName="maritalStatusId"
                                                            [clearable]="false"
                                                            [ngClass]="{ 'is-invalid': submitted && f.maritalStatusId.errors}">
                                                        </ng-select>
                                                        <div *ngIf="submitted && f.maritalStatusId.errors" class="invalid-feedback">
                                                            <div *ngIf="f.maritalStatusId.errors.required">Maritial Status is required</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-3 row">
                                                    <label class="col-md-4 col-form-label">On Behalf :</label>
                                                    <div class="col-md-8">
                                                        <ng-select [items]="onBehalfs" placeholder="Select On Behalf" bindLabel="name"
                                                            bindValue="onBehalfId" (change)="selectOnBehalf()" [(ngModel)]="selectedOnBehalf"
                                                            formControlName="onBehalfId" [clearable]="false"
                                                            [ngClass]="{ 'is-invalid': submitted && f.onBehalfId.errors}">
                                                        </ng-select>
                                                        <div *ngIf="submitted && f.onBehalfId.errors" class="invalid-feedback">
                                                            <div *ngIf="f.onBehalfId.errors.required">On Behalf is required</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <ul class="list-inline wizard mb-0">
                                            <li class="previous list-inline-item"><button class="btn btn-primary"
                                                    awPreviousStep>Back</button>
                                            </li>
                                            <li class="next list-inline-item float-end"><button class="btn btn-primary"
                                                    awNextStep>Register</button>
                                            </li>
                                        </ul>
                                    </aw-wizard-step>

                                    <aw-wizard-step>
                                        <ng-template awWizardStepSymbol>
                                            <i class="fas fa-check-circle"></i>
                                        </ng-template>
                                               <div class="row">
                                            <div class="col-12">
                                                <div class="mb-3 row">
                                                    <label class="col-md-4 col-form-label" for="email">Email</label>
                                                    <div class="col-md-8">
                                                        <input type="email" formControlName="email" class="form-control"
                                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email"
                                                            placeholder="Email" />
                                
                                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                                        </div>
                                                    </div>
                                                </div>
                                   
                                                <div class="mb-3 row">
                                                    <label class="col-md-4 col-form-label" for="phoneNumber">Phone Number:</label>
                                                    <div class="col-md-8">
                                                        <ngx-intl-tel-input class="phoneNumber-style " 
                                                        [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                                                        [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
                                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15" customPlaceholder="Mobile Number"
                                                        [phoneValidation]="true"  name="phoneNumber" formControlName="phoneNumber"  [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }">
                                                      </ngx-intl-tel-input>
                                
                                                        <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                                                            <div *ngIf="f.phoneNumber.errors.required">Phone number is required</div>
                                                            <div *ngIf="f.phoneNumber.errors.phoneNumber">Phone number must be a valid Phone
                                                                Number
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-3 row">
                                                    <label class="col-md-4 col-form-label" for="password">Password</label>
                                                    <div class="col-md-8">
                                                        <input type="password" formControlName="password" class="form-control"
                                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" id="password"
                                                            placeholder="Password" />
                                
                                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <ul class="list-inline wizard mb-0">
                                            <li class="previous list-inline-item"><button class="btn btn-primary"
                                                    awPreviousStep>Back</button>
                                            </li>
                                            <li class="next list-inline-item float-end"><button class="btn btn-primary" type="submit"
                                                awNextStep>Register</button>
                                        </li>
                                        </ul>
                                    </aw-wizard-step>
                                </aw-wizard>




                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="card shadow rounded border-0 ms-lg-5">
                    <div class="card-body">
                        <h5 class="card-title fw-bold">Register Your Appointment</h5>
                        <h6>We here to help you 24/7 with experts</h6>

                        <form class="login-form">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">First name <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" placeholder="First Name"
                                                name="s" required="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                            <input type="email" class="form-control ps-5" placeholder="Email"
                                                name="email" required="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label class="form-label">Phone no. <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                                            <input type="number" name="number" class="form-control ps-5"
                                                placeholder="Phone no." required="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            I Accept <a routerLink="/index-it-solution-two" class="text-primary">Terms
                                                And Condition</a>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="d-grid">
                                        <button class="btn btn-primary">Register</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> -->
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Features -->
<section class="py-4 bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                    <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                        <i class="uil uil-analytics text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h4 class="title">100% Verified Profiles</h4>
                        <p class="text-muted para mb-0">100% verified and filtered profiles</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                    <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                        <i class="uil uil-airplay text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h4 class="title">Most Trusted</h4>
                        <p class="text-muted para mb-0">The perfect matrimonial site for a perfect match</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                    <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                        <i class="uil uil-clapper-board text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h4 class="title">100% Secure</h4>
                        <p class="text-muted para mb-0">Your Information is 100% safe with us</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-12">
                <div class="d-flex features pt-4 pb-4">
                    <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                        <i class="uil uil-users-alt text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h4 class="title">Best Matches</h4>
                        <p class="text-muted para mb-0">Intelligent Matchmaking Algorithm</p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Features -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                            <div class="card-body p-0">
                                <img src="assets/images/ss1.jpg" class="img-fluid" alt="work-image">
                                <div class="overlay-work bg-dark"></div>
                                <!-- <div class="content">
                                    <a href="javascript:void(0)" class="title text-white d-block fw-bold">Web
                                        Development</a>
                                    <small class="text-light">IT & Software</small>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-6 col-6">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                    <div class="card-body p-0">
                                        <img src="assets/images/course/online/ab02.jpg" class="img-fluid"
                                            alt="work-image">
                                        <div class="overlay-work bg-dark"></div>
                                        <div class="content">
                                            <a href="javascript:void(0)"
                                                class="title text-white d-block fw-bold">Michanical Engineer</a>
                                            <small class="text-light">Engineering</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-lg-12 col-md-12 mt-4 pt-2">
                                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                    <div class="card-body p-0">
                                        <img src="assets/images/course/online/ab03.jpg" class="img-fluid"
                                            alt="work-image">
                                        <div class="overlay-work bg-dark"></div>
                                        <div class="content">
                                            <a href="javascript:void(0)"
                                                class="title text-white d-block fw-bold">Chartered Accountant</a>
                                            <small class="text-light">C.A.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end col-->

            <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
                <div class="ms-lg-4">
                    <div class="section-title">
                        <span class="badge bg-soft-primary rounded-pill fw-bold">FindOne Matrimony.com</span>
                        <h4 class="title mb-4 mt-3">Smart way to Find your One</h4>
                        <p class="text-muted para-desc  mb-0">FindOne Matrimony.com will help you find your perfect
                            partner
                            for life. We connect families together.You can find your ideal partner using our advanced
                            filters and match making algorithms based on community,location, education, profession and
                            interests
                        </p>
                    </div>

                    <div class="d-flex mt-4 pt-2">
                        <i class="uil uil-bullseye h5 text-primary"></i>
                        <div class="flex-1 ms-2">
                            <h5>Privacy Protection​</h5>
                            <p class="mb-0 text-muted">The data you choose to share with us is safe and secure.</p>
                        </div>
                    </div>

                    <div class="d-flex mt-4 pt-2">
                        <i class="uil uil-clapper-board h5 text-primary"></i>
                        <div class="flex-1 ms-2">
                            <h5>Simple & Effective UI​</h5>
                            <p class="mb-0 text-muted">Easy to access, understand, and use</p>
                        </div>
                    </div>

                    <div class="d-flex mt-4 pt-2">
                        <i class="uil uil-create-dashboard h5 text-primary"></i>
                        <div class="flex-1 ms-2">
                            <h5>Advanced Searching</h5>
                            <p class="mb-0 text-muted">Help you find your partner</p>
                        </div>
                    </div>

                    <!-- <div class="mt-4 pt-2">
                        <a href="https://1.envato.market/4n73n" target="_blank" class="btn btn-primary m-1">Read More <i
                                class="uil uil-angle-right-b align-middle"></i></a>
                    </div> -->
                </div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Experts in Match making</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Come join <span class="text-primary fw-bold">FindOne
                            Matrimony.com</span> to find your other half!</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="monitor" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Verified Profiles</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">100% Privacy</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="eye" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Best Matches</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="bold" class="fea icon-ex-md text-primary"></i-feather>>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Messaging System</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="feather" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Advanced Filtering</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Simple UI</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="user-check" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Affordable Plans</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="git-merge" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Dedicated Support</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
                    <div class="icon text-center rounded-circle me-3">
                        <i-feather name="settings" class="fea icon-ex-md text-primary"></i-feather>
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">The Most Trusted</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <!-- <div class="col-12 mt-4 pt-2 text-center">
                <a href="javascript:void(0)" class="btn btn-primary">See More <i class="uil uil-angle-right"></i></a>
            </div> -->
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start -->
<section class="section pt-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="video-solution-cta position-relative" style="z-index: 1;">
                    <div class="position-relative py-5 rounded"
                        style="background: url('assets/images/it/cta.jpg') top;">
                        <div class="bg-overlay rounded bg-primary bg-gradient" style="opacity: 0.8;"></div>

                        <div class="row">
                            <div class="col-lg-3 col-6 my-4">
                                <div class="counter-box text-center px-lg-4">
                                    <i class="uil uil-users-alt text-white-50 h2"></i>
                                    <h1 class="mb-0 text-white"><span class="counter-value" [CountTo]="10000"
                                            [from]="6000" [duration]="4"></span>+</h1>
                                    <h6 class="counter-head text-white-50">Profiles</h6>
                                </div>
                                <!--end counter box-->
                            </div>
                            <!--end col-->

                            <div class="col-lg-3 col-6 my-4">
                                <div class="counter-box text-center px-lg-4">
                                    <i class="uil uil-schedule text-white-50 h2"></i>
                                    <h1 class="mb-0 text-white"><span class="counter-value" [CountTo]="15" [from]="1"
                                            [duration]="4"></span>+</h1>
                                    <h6 class="counter-head text-white-50">Years Experience</h6>
                                </div>
                                <!--end counter box-->
                            </div>
                            <!--end col-->

                            <div class="col-lg-3 col-6 my-4">
                                <div class="counter-box text-center px-lg-4">
                                    <i class="uil uil-file-check-alt text-white-50 h2"></i>
                                    <h1 class="mb-0 text-white"><span class="counter-value" [CountTo]="1000"
                                            [from]="600" [duration]="4"></span>+</h1>
                                    <h6 class="counter-head text-white-50">Happy Stories</h6>
                                </div>
                                <!--end counter box-->
                            </div>
                            <!--end col-->

                            <div class="col-lg-3 col-6 my-4">
                                <div class="counter-box text-center px-lg-4">
                                    <i class="uil uil-hard-hat text-white-50 h2"></i>
                                    <h1 class="mb-0 text-white">24x7</h1>
                                    <h6 class="counter-head text-white-50">Dedicated Support Team</h6>
                                </div>
                                <!--end counter box-->
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>

                    <div class="content mt-md-5 mt-4">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 mt-4">
                                <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
                            </div>
                            <!--end col-->
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row -->
        <div class="feature-posts-placeholder bg-light"></div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End section -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Latest News</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Read our succes stories here.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <app-blog [blogData]="blogData"></app-blog>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->