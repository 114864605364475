import { Component, OnInit } from '@angular/core';

interface job {
  image: string,
  message: string,
  designation: string,
  icon: string,
  name: string,
  location: string,
  list: string[],
};

@Component({
  selector: 'app-page-jobs',
  templateUrl: './page-jobs.component.html',
  styleUrls: ['./page-jobs.component.css']
})

/**
 * Page Jobs Component
 */
export class PageJobsComponent implements OnInit {

  /**
   * Jobs Data
   */
  jobsData: job[] = [
    {
      image: "assets/images/job/full.jpg",
      designation: "SDE-II - Full Stack",
      message: "Full Time",
      icon: "assets/images/logo-icon.png",
      name: "DATAEQUINOX",
      location: "Kochi, India",
      list: ['5+ Years Expirence', "Working Hours- 8hr", "React JS, Native, Node JS, AWS"]
    },
    {
      image: "assets/images/job/full.jpg",
      designation: "SDE-I - Web Developer",
      message: "Full Time",
      icon: "assets/images/logo-icon.png",
      name: "DATAEQUINOX",
      location: "Kochi, India",
      list: ['1+ Years Expirence', "Working Hours- 8hr", "React JS, JAVA, Node JS"]
    },
    {
      image: "assets/images/job/part.jpg",
      designation: "UX Designer - II",
      message: "Part Time",
      icon: "assets/images/logo-icon.png",
      name: "DATAEQUINOX",
      location: "Kochi, India",
      list: ['3+ Years Expirence', "Working Hours- 3hr", "Adobe XD, Figma, Adobe CC"]
    },
    {
      image: "assets/images/job/full.jpg",
      designation: "SDE-I - Intern",
      message: "Full Time",
      icon: "assets/images/logo-icon.png",
      name: "DATAEQUINOX",
      location: "Kochi, India",
      list: ['0+ Year Expirence', "Working Hours- 8hr", "Angular, Flutter, Java, Python"]
    },
    {
      image: "assets/images/job/full.jpg",
      designation: "SDE-I - Intern",
      message: "Full Time",
      icon: "assets/images/logo-icon.png",
      name: "DATAEQUINOX",
      location: "Kochi, India",
      list: ['0+ Year Expirence', "Working Hours- 8hr", "React, React Native, NodeJS, Python"]
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
